import React from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import ContactForm from "../components/contact-form.js"

export default () => (
    <div>
        <Header/>
        <ContactForm/>
        <Footer />
    </div>
)